import HomePage from './HomePage'
import BlogList from './BlogList'
import BlogArticle from './BlogArticle'
import BlogDetailsRouted from './BlogDetailsRouted'
import Program from './Program'
import FullPage from './FullPage'

const layouts = {
  HomePage: HomePage,
  BlogList: BlogList,
  BlogArticle: BlogArticle,
  BlogDetailsRouted: BlogDetailsRouted,
  Program: Program,
  FullPage: FullPage,
}

export default layouts
