import React from 'react'

import {Helmet} from "react-helmet";
import ReactMarkdown from 'react-markdown'

import moment from 'moment'
import 'moment/locale/ro'

import {
  useParams,
  NavLink
} from "react-router-dom";

import clsx from 'clsx'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/styles';

import {
  StyledEngineProvider,
  Grid,
  Container,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Typography,
  Slide,
  Divider,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import ArrowBack from '@material-ui/icons/ArrowBack'
import AuthorIcon from '@material-ui/icons/Person'
import OrganisationIcon from '@material-ui/icons/AccountBalance'

import RouteScrollTop from '../comps/RouteScrollTop'

// import componentList from '../../core/ComponentIterator'

// const styles = theme => ({})

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    marginTop: 0,
    textTransform: 'uppercase',
  },
  dialogButton: {
    position: 'absolute',
    top: 50,
    left: 50,
  },
  customDialog: {
    '& h2:first-of-type': {
      marginTop: 0,
    },
  },
  
  blogHero: {
    width: '100%',
  },
  blogListDate: {
    color: 'white',
    textAlign: 'center',

    '&.MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
    },

    '& .MuiCardContent-root': {
      padding: 8
    }
  },

  backButton: {
    minWidth: 'unset',
    marginRight: '16px !important',
    padding: 6,
    '& .MuiButton-startIcon': {margin: 0}
  },

  cardTitle: {
    marginTop: 0,
    color: theme.palette.primary.main,
    '&>p': {
      margin: 0,
    },
  },

  listLink: {
    textDecoration: 'none !important',
    '&:hover h3': {
      color: `${theme.palette.secondary.main} !important`
    }
  },
  listCard: {
    '&:hover': {backgroundColor: theme.palette.secondary.main},
    '&:hover h3': {
      color: `white !important`
    }
  },
  listCardContent: {
    padding: '16px !important'
  },
  listCardTitle: {
    marginBottom: 4,
    color: theme.palette.primary.main,
    fontSize: '16px',
  },
}))

const TopicContent = ({ config, section, dialogData, classes }) => {
  // const domenii = dialogData.keywords && dialogData.keywords.split(',')
  // console.log('TopicDetails: ', dialogData)
  return (
    <StyledEngineProvider injectFirst>
      <Helmet>
        <title>{dialogData.title} : {config.siteTitle} {section}</title>
        <meta name="description" content={dialogData.intro} />
        <meta name="author" content={`pentru ${config.siteCopyrightOwner} <${config.siteContactEmail}>`} />
        <meta 
          property="og:title" 
          content={`${dialogData.title} : ${config.siteTitle} ${section}`}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={dialogData.intro}
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content={`https://noapteacercetatorilor.educatiepentrustiinta.ro/blog/images/${dialogData.hero}`}
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content={`https://noapteacercetatorilor.educatiepentrustiinta.ro/blog/${dialogData.slug}`}
          data-react-helmet="true"
        />
        <meta 
          property="og:type" 
          content="article" 
          data-react-helmet="true"
        />
      </Helmet>

      <RouteScrollTop />
      <div className="blogContent" itemscope itemtype="https://schema.org/NewsArticle">
        <Grid container spacing={3}>
          <Grid item 
            xs={12}
            // sm={dialogData.datePublished ? 10 : 12}
            // md={dialogData.datePublished ? 11 : 12}
            style={{ margin: '0 auto' }}
          >
            {dialogData.datePublished && (
              <p>
                <Button 
                  variant="link" 
                  component={NavLink}
                  to="/blog"
                  startIcon={<ArrowBack />}
                  className={classes.backButton}
                />

                {`${moment(dialogData.datePublished).format('D')} ${moment(dialogData.datePublished).format('MMMM')} ${moment(dialogData.datePublished).format('YYYY')}`}
              </p>
            )}
            <Typography component="h1" variant="h4" className={classes.cardTitle} itemprop="headline">{dialogData.title}</Typography>
          <Divider />
          </Grid>
          {dialogData.icon && (
            <Grid item xs={7} sm={4} md={3} style={{ margin: '0 auto' }}>
              <Box className={classes.cardImageContainer}>
                <img
                  alt={dialogData.title}
                  src={dialogData.icon}
                  className={classes.cardImage}
                />
              </Box>
            </Grid>
          )}
          {(dialogData.author || dialogData.authorOrganisation) && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" component="div" gutterBottom itemprop="author" itemscope itemtype="https://schema.org/Person">
                  <AuthorIcon
                    color="disabled"
                    style={{ verticalAlign: 'bottom' }}
                  />{' '}
                  <ReactMarkdown
                    children={dialogData.author}
                    components={{ 
                      // p: 'span' 
                      p({children}) {
                        return <span itemprop="name">{children}</span>
                      } 
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body2"
                  component="div"
                  align="right"
                  gutterBottom
                >
                  <OrganisationIcon
                    color="disabled"
                    style={{ verticalAlign: 'bottom' }}
                  />{' '}
                  <ReactMarkdown
                    children={dialogData.authorOrganisation}
                    components={{ p: 'span' }}
                  />
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            {dialogData.hero ? (
              <img
                className={classes.blogHero}
                src={`//noapteacercetatorilor.educatiepentrustiinta.ro/blog/images/${dialogData.hero}`}
                alt={dialogData.title}
                itemprop="image"
              />
            ) : (
              <Divider />
            )}
          </Grid>
          {dialogData.content && (
            <Grid
              item
              xs={12}
              // dangerouslySetInnerHTML={createMarkup(dialogData.content)}
              style={{ paddingTop: 12 }}
            >
              <Typography variant="body1" component="div" gutterBottom>
                <ReactMarkdown children={dialogData.intro} />
              </Typography>
              <Divider />
              <Typography variant="body2" component="div" gutterBottom>
                <ReactMarkdown children={dialogData.content} />
              </Typography>
            </Grid>
          )}
          {dialogData.datePublished && (
            <>
              <Grid
                item
                xs={12}
                style={{ paddingTop: 8 }}
              >
                <Divider style={{ marginBottom: 8 }} />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ paddingTop: 8 }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  gutterBottom
                  align="left"
                  style={{ textTransform: 'capitalize' }}
                >
                  Last updated: <em itemprop="dateModified" content={`${moment(dialogData.dateUpdated).format('YYYY')}-${moment(dialogData.dateUpdated).format('MM')}-${moment(dialogData.dateUpdated).format('D')}`}>{moment(dialogData.dateUpdated).format('dddd, D MMMM YYYY')}</em>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </StyledEngineProvider>
  )
}

const ArticleList =({data, classes}) => {
  return (
    <StyledEngineProvider injectFirst>
      <Typography component="h2" variant="h5">Alte articole</Typography>
      <Grid container spacing={3} mt={0}>
          {data.map((item, index) => {
            // console.log(data[item])
            if (JSON.parse(item.display)) {
              return (
                <Grid item xs={12}>
                  <NavLink to={`/blog/${item.slug}`} className={classes.listLink}>
                    <Card elevation={0} className={classes.listCard}>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={`//noapteacercetatorilor.educatiepentrustiinta.ro/blog/images/${item.hero}`}
                        title={item.title}
                      />
                      <CardContent className={classes.listCardContent}>
                        <Typography component="h3" variant="h5" className={classes.listCardTitle}>{item.title}</Typography>
                        {item.datePublished && (<div style={{textAlign: 'right'}}><small>{`${moment(item.datePublished).format('D')} ${moment(item.datePublished).format('MMMM')} ${moment(item.datePublished).format('YYYY')}`}</small></div>)}
                      </CardContent>
                    </Card>
                  </NavLink>
                </Grid>
              )
            }
          })}
      </Grid>
    </StyledEngineProvider>
  )
}

export default (function BlogDetailsRouted(props) {
  const localClasses = useStyles()
  const theme = useTheme()

  let { slug } = useParams();

  const {
    config, dataSource, siteLanguage, pageName } = props

  const articleData = dataSource.filter(article => article.slug === slug)[0];
  
  const articleListData = dataSource.filter(article => article.slug !== slug).slice(0,6);
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={9}>
        <TopicContent dialogData={articleData} classes={localClasses} config={config} section={pageName} />
      </Grid>
      <Grid item xs={12} md={3}>
        <ArticleList data={articleListData} classes={localClasses} />
      </Grid>
    </Grid>
  )
})
